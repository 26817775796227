import * as React from 'react';
import {Link} from 'gatsby';

import Layout from 'components/Layout';
import {PageHeader} from 'components/Headings';

import 'styles/App.scss';

// markup
const NotFoundPage = () => {
  return (
    <Layout hasContact preloadFooter seomatic={{metaTitleContainer: '<title>Not found</title>'}}>
      <PageHeader heading="That page doesn&rsquo;t exist" subheading="Sorry, we couldn't find what you&rsquo;re looking for 😔" />
    </Layout>
  );
};

export default NotFoundPage;
